import React from 'react';

import type { CollabEditOptions } from '@atlaskit/editor-common/collab';

import { expValEquals } from '@confluence/feature-experiments';
import { EditorPresenceAvatarGroupLoader } from '@confluence/editor-presence-avatar-group';
import { useSessionData, AccessStatus } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { fg } from '@confluence/feature-gating';

import { TeammatePresenceAvatarGroup } from './TeammatePresenceAvatarGroup';
import { TeammatePresenceContainer } from './useTeammatePresenceStore';
import { TeammatePresencePlaceholder } from './TeammatePresencePlaceholder';
import { NCSProvider } from './NCSProvider';
import { TeammatePresenceLivePagePlaceholder } from './TeammatePresenceLivePagePlaceholder';

type TeammatePresenceProps = {
	collabEditOption?: CollabEditOptions;
	contentId: string;
	contentType: string | undefined | null;
	spaceKey: string;
	isEditorActionBarSSREnabled?: boolean;
	isLivePage?: boolean;
};

const TeammatePresenceComponent = ({
	contentId,
	contentType,
	spaceKey,
	collabEditOption,
	isEditorActionBarSSREnabled,
}: TeammatePresenceProps) => {
	const { edition, accessStatus } = useSessionData();
	const isLivePage = useIsLivePage();
	const isEditPage = useIsEditorPage();

	// A double check
	const isCorrectEdition =
		edition === ConfluenceEdition.FREE || edition === ConfluenceEdition.STANDARD;

	const isCorrectTypeOfUser =
		accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS ||
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	const isEligibleForTeammatePresence = isCorrectEdition && isCorrectTypeOfUser && !isLivePage;
	if (isEditorActionBarSSREnabled && process.env.REACT_SSR) {
		if (isEditPage) {
			return <TeammatePresencePlaceholder />;
		} else if (isLivePage) {
			return <TeammatePresenceLivePagePlaceholder />;
		} else if (isEligibleForTeammatePresence) {
			return null;
		}
	}

	/**
	 * The feature gate uses an audience to determine who gets through.
	 * The audience is determined by size of instance (2 <= MAU <= 500) + edition (FREE | STANDARD)
	 */
	// This check inherently includes the right edition + type of user + checks feature gate
	if (isEligibleForTeammatePresence && fg('cc_onboarding_teammate_presence_gate')) {
		if (expValEquals('cc_onboarding_teammate_presence', 'cohort', 'experiment')) {
			return (
				<TeammatePresenceContainer
					key={`${contentId}-${contentType}-${spaceKey}`}
					contentId={contentId}
					contentType={contentType}
					spaceKey={spaceKey}
				>
					<NCSProvider contentId={contentId}>
						<TeammatePresenceAvatarGroup collabEditOption={collabEditOption} />
					</NCSProvider>
				</TeammatePresenceContainer>
			);
		}
	}

	// I surrounded only our query with the error display because I didn't want to consume the errors from `EditorPresenceAvatarGroupLoader`.
	return (
		<>
			{collabEditOption && (isEditPage || isLivePage) ? (
				<EditorPresenceAvatarGroupLoader
					collabEditOption={collabEditOption}
					isEditorActionBarSSREnabled={isEditorActionBarSSREnabled}
					isLivePage={isLivePage}
					isEditPage={isEditPage}
				/>
			) : null}
		</>
	);
};

export const TeammatePresence = (props: TeammatePresenceProps) => {
	return (
		<ErrorBoundary attribution={Attribution.CC_ONBOARDING}>
			<TeammatePresenceComponent {...props} />
		</ErrorBoundary>
	);
};
